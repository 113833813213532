/* You can add global styles to this file, and also import other style files */
@import './styles/mixins';
// @import "styles/coreui.scss";

@include font-face("ArchSansWeb-Regular", "./assets/fonts/ArchSansWeb-Regular");

html {
  font-size: 14px;
}

body {
  background-color: #f5f5f5;
  color: #292929;
  font-size: 1rem;
  font-family: "ArchSansWeb-Regular";
}

main {
  position: relative;
  top: 50px;
  padding: 15px;
  min-width: 570px;
}

spinner {
  position: relative;
  top: 50px;
  width: 100%;
  z-index: 10;
  text-align: center;
  font-size: 2rem;
}

h1 {
  font-size: 4rem;
}

h2 {
  font-size: 1.3rem;
  font-weight: bold;
}

h3, .nav-link {
  font-size: 1.15rem;
}

hr {
  border: 1px solid #ffc836;
  border-right: none;
  border-left: none;
  width: 363px;
  height: 2px;
  margin: 10rem auto 2rem auto;
}

a {
  color: #292929 !important;

  &:hover {
    text-decoration: underline;
  }
}

button:hover {
  cursor: pointer !important;
}

angular2-smart-table-cell, .angular2-smart-sort-link, .angular2-smart-title {
  font-size: 1rem;
  color: #292929 !important;
}

tr, thead {
  border: 1px solid #e0e0e0;

  .ng2-smart-filters, .ng2-smart-titles {
    border: none !important;
  }
}

th, td {
  text-align: center;
}

.accordion-markup {
  font-size: large;
  float: right;
}

.action-icon {
  font-size: 1rem;
}

.alert-warning {
  cursor: pointer;
}

.bold {
  font-weight: bold;
}

.breadcrumb {
  background-color: #292929;
  color: #e4e5e6;
  height: 20px;
}

.breadcrumb-item {
  &::before {
    color: #f8af00 !important;
  }

  a {
    color: #e0e0e0 !important;
  }
}

.btn-outline-info {
  background-color: white;
  border: solid 1px #34b36c !important;
  color: #34b36c !important;

  &:hover {
    color: white !important;
    background-color: #34b36c !important;
  }

  &:disabled {
    color: white !important;
    background-color: #909090 !important;
    border: solid 1px #909090 !important;

    &:hover {
      color: white !important;
      background-color: #909090 !important;
      border: solid 1px #909090 !important
    }
  }
}

.btn-xs {
  padding: 2px;
  height: 30px !important;
}

.card {
  margin-bottom: 15px;
}

.datasync::before {
  display: block;
  margin-bottom: .25rem;
  width: 32px;
  height: .25rem;
  content: '';
  background-color: #bd0017;
}

.dotted-link {
  border-bottom: 1px black dotted;
  word-wrap: break-word;

  &:hover {
    text-decoration: none !important;
    border-bottom: 1px black solid;
  }

  &:focus {
    text-decoration: none !important;
  }
}

.dropdown-menu {
  color: #292929;
  background-color: #f5f5f5;
  padding: 5px;
  margin-top: 10px;
  left: auto;
  right: 0px;
  width: 160px;
}

.dropdown-toggle {
  color: #292929;
  background-color: #f5f5f5;
  margin-top: 8px;
  padding: 2px;
  height: 25px !important;
}

.header-button {
  display: flex;
  flex-direction: row;
  justify-content: end;
}

.header-info {
  position: fixed;
  top: 15px;
  left: 40%;
  z-index: 11;
  color: #f5f5f5;
}

.home-card {
  width: 300px;
}

.lang-switch {
  padding-top: 6px;

  select {
    height: 27px !important;
    width: 50px;
    padding: 2px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
    background-color: #f5f5f5;
  }

  &:after {
    content: '▼';
    font: 11px "Consolas", monospace;
    color: #292929;
    right: 75px;
    top: 20px;
    position: absolute;
  }
}

.icon {
  width: 75px;
  position: relative;
  bottom: 10px;
}

.navbar {
  background-color: #292929;
  position: fixed;
  z-index: 10;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
}

.ng2-smart-pagination {
  display: flex !important;
  justify-content: flex-end;
}

.pac-container:after {
  /* Disclaimer: not needed to show 'powered by Google' if also a Google Map is shown */
  background-image: none !important;
  height: 0px;
  padding: 0px;
  margin: 0px;
}

.panel-heading {
  cursor: pointer;
  height: 45px;
}

.page-item.active .page-link {
  background-color: #34b36c;
  border-color: #34b36c;
}

.product-finder::before {
  display: block;
  margin-bottom: .25rem;
  width: 32px;
  height: .25rem;
  content: '';
  background-color: #f8af00;
}

.pull-center {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.search {
  margin: auto;
  margin-bottom: 15px;
}

.sebm-google-map-container {
  height: 600px !important;
}

.small-alert {
  padding: 5px 10px;
  position: relative;
  bottom: 3px;
}

.store-finder::before {
  display: block;
  margin-bottom: .25rem;
  width: 32px;
  height: .25rem;
  content: '';
  background-color: #34b36c;
}

.tab-pane {
  padding: 15px;
  background-color: #ffffff;
  border-left: 1px solid #dddddd;
}

.text-green {
  color: #34b36c;
}

.text-grey {
  color: #909090 !important;
}

.text-red {
  color: #bd0017 !important;
}

.text-yellow {
  color: #f89406;
}


// Override bootstrapcss
.btn-group-vertical>.btn, .btn-group>.btn {
  position: relative;
  flex: 0 1 auto;
}

// Override angular table
angular2-smart-table-pager {
  display: inline !important;
}

.angular2-smart-pagination {
  display: flex !important;
  justify-content: flex-end;
}

.page-link-prev {
  font-size: 14px !important;
}

.page-link-next {
  font-size: 14px !important;
}

angular2-smart-table > div {
  width: 100%;
}
